const useLayoutEventHandlers = () => {
	const { data } = useAppInitData();
	const { logoutUser } = useLogout();
	const { open } = useNlcModals();

	useEvent(["SHOW_VERIFICATION_PHONE"], (event: Event) => {
		const { detail } = event as CustomEvent;
		open("LazyOModalPhoneConfirmation", { location: detail?.location });
	});
	useEvent(["COMPLETE_PROFILE_REWARD"], (event: Event) => {
		const { detail } = event as CustomEvent<{ rewardEntry: boolean; showRewardsModal: boolean }>;
		if (detail?.rewardEntry) {
			window?.$cash?.$router?.push("/");
		}

		if (detail?.showRewardsModal) {
			open("LazyOModalPhoneConfirmation", { confirmPhoneDeposit: !data.value?.phone });
		}
	});
	useEvent(["LOGOUT_AND_OPEN_AUTH_MODAL"], () => {
		if (window?.$cash?.$store?.getters?.["cash/isOpen"]) {
			window?.$cash?.$router?.push("/");
		}
		logoutUser();
		open("LazyOModalLogin");
	});
	useEvent(["OPEN_INVITE_FRIENDS_MODAL"], () => {
		open("LazyOModalPromoInviteFriends");
	});
	useEvent(["OPEN_PROFILE"], (event: Event) => {
		const { detail } = event as CustomEvent<{ rewardEntry: boolean; redeemForm: boolean }>;
		open("LazyOModalProfile", { rewardEntry: detail?.rewardEntry, redeemForm: detail?.redeemForm });
	});
};
export default useLayoutEventHandlers;
